import styled from 'styled-components';
import { lighten } from 'polished';
import { Menu } from 'antd';

export const Container = styled(Menu)`
  background-color: var(--header-background);
  user-select: none;
`;

export const Item = styled(Menu.Item)`
  background-color: var(--header-background);
  color: var(--menu-primary) !important;

  &::after {
      border-right: none !important;
    }

  &:hover, &:active, &:focus {
    background-color: #e6f7ff;
    color: var(--menu-primary-hover) !important;

    &::after {
      border-right: none !important;
    }
  }
`;

export const SubMenu = styled(Menu.SubMenu)`
  background-color: var(--header-background);
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background-color: #86aaaa;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-left: .5rem;

  h1 {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
  }
`;