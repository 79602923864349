export function attributeIndexRequest(filters) {
  return {
    type: '@attributes/INDEX_REQUEST',
    payload: { filters },
  };
}

export function attributeIndexSuccess(data) {
  return {
    type: '@attributes/INDEX_SUCCESS',
    payload: { data },
  };
}

export function attributeIndexFailure() {
  return {
    type: '@attributes/INDEX_FAILURE',
  };
}

export function attributeCreateRequest(data) {
  return {
    type: '@attributes/CREATE_REQUEST',
    payload: { data },
  };
}

export function attributeCreateSuccess(data) {
  return {
    type: '@attributes/CREATE_SUCCESS',
    payload: { data },
  };
}

export function attributeCreateFailure() {
  return {
    type: '@attributes/CREATE_FAILURE',
  };
}

export function attributeReadRequest(id) {
  return {
    type: '@attributes/READ_REQUEST',
    payload: { id },
  };
}

export function attributeReadSuccess(data) {
  return {
    type: '@attributes/READ_SUCCESS',
    payload: { data },
  };
}

export function attributeReadFailure() {
  return {
    type: '@attributes/READ_FAILURE',
  };
}

export function attributeUpdateRequest(data) {
  return {
    type: '@attributes/UPDATE_REQUEST',
    payload: { data },
  };
}

export function attributeUpdateSuccess(data) {
  return {
    type: '@attributes/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function attributeUpdateFailure() {
  return {
    type: '@attributes/UPDATE_FAILURE',
  };
}

export function attributeDeleteRequest(id) {
  return {
    type: '@attributes/DELETE_REQUEST',
    payload: { id },
  };
}

export function attributeDeleteSuccess() {
  return {
    type: '@attributes/DELETE_SUCCESS',
  };
}

export function attributeDeleteFailure() {
  return {
    type: '@attributes/DELETE_FAILURE',
  };
}
