import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';

import Home, { Breadcumb as HomeBreadcumb } from '~/pages/Home';
import Category, { Breadcumb as CategoryBreadcumb } from '~/pages/Category';
import Attribute, { Breadcumb as AttributeBreadcumb } from '~/pages/Attribute';
import Product, { Breadcumb as ProductBreadcumb } from '~/pages/Product';
import ProductCreate, { Breadcumb as ProductCreateBreadcumb } from '~/pages/Product/Create';
import User, { Breadcumb as UserBreadcumb } from '~/pages/User';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" exact component={SignIn} />

      <Route path="/" exact component={Home} isPrivate breadcrumb={HomeBreadcumb} />
      <Route path="/categories" exact component={Category} isPrivate breadcrumb={CategoryBreadcumb} />
      <Route path="/attributes" exact component={Attribute} isPrivate breadcrumb={AttributeBreadcumb} />
      <Route path="/products" exact component={Product} isPrivate breadcrumb={ProductBreadcumb} />
      <Route path="/products/create" exact component={ProductCreate} isPrivate breadcrumb={ProductCreateBreadcumb} />
      <Route path="/users" exact component={User} isPrivate breadcrumb={UserBreadcumb} />

      <Redirect to="/" />
    </Switch>
  );
}
