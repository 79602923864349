import React from 'react';
import PropTypes from 'prop-types';

import { Container, Card } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Container>
      <Card>
        {children}
      </Card>
    </Container>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
