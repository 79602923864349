export function attributeValueIndexRequest(id, filters) {
  return {
    type: '@attributeValues/INDEX_REQUEST',
    payload: { id, filters },
  };
}

export function attributeValueIndexSuccess(data) {
  return {
    type: '@attributeValues/INDEX_SUCCESS',
    payload: { data },
  };
}

export function attributeValueIndexFailure() {
  return {
    type: '@attributeValues/INDEX_FAILURE',
  };
}

export function attributeValueCreateRequest(data, attributeId) {
  return {
    type: '@attributeValues/CREATE_REQUEST',
    payload: { data, attributeId },
  };
}

export function attributeValueCreateSuccess(data) {
  return {
    type: '@attributeValues/CREATE_SUCCESS',
    payload: { data },
  };
}

export function attributeValueCreateFailure() {
  return {
    type: '@attributeValues/CREATE_FAILURE',
  };
}

export function attributeValueReadRequest(id, attributeId) {
  return {
    type: '@attributeValues/READ_REQUEST',
    payload: { id, attributeId },
  };
}

export function attributeValueReadSuccess(data) {
  return {
    type: '@attributeValues/READ_SUCCESS',
    payload: { data },
  };
}

export function attributeValueReadFailure() {
  return {
    type: '@attributeValues/READ_FAILURE',
  };
}

export function attributeValueUpdateRequest(data) {
  return {
    type: '@attributeValues/UPDATE_REQUEST',
    payload: { data },
  };
}

export function attributeValueUpdateSuccess(data) {
  return {
    type: '@attributeValues/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function attributeValueUpdateFailure() {
  return {
    type: '@attributeValues/UPDATE_FAILURE',
  };
}

export function attributeValueDeleteRequest(id, attributeId) {
  return {
    type: '@attributeValues/DELETE_REQUEST',
    payload: { id, attributeId },
  };
}

export function attributeValueDeleteSuccess() {
  return {
    type: '@attributeValues/DELETE_SUCCESS',
  };
}

export function attributeValueDeleteFailure() {
  return {
    type: '@attributeValues/DELETE_FAILURE',
  };
}
