import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, DatePicker, Select, Skeleton } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { listYesNo } from '~/helpers/lists';
import { isNullOrEmpty } from '~/helpers/util';

import { userUpdateRequest } from '~/store/modules/user/actions';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Container } from './styles';

export default function Update({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const saving = useSelector(store => store.user.loading);
  const error = useSelector(store => store.user.error);
  const registry = useSelector(store => store.user.registry);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  function onFinish(values) {
    console.log({ values });
    dispatch(userUpdateRequest(values));
    setIsSaving(true);
  }

  useEffect(() => {
    setLoading(true);
  }, []);

  useMemo(() => {
    setLoading(true);

    if (!isNullOrEmpty(registry?.id)) {
      form.setFieldsValue({
        id: registry.id,
        name: registry.name,
        login: registry.login,
        email: registry.email,
        password: '',
        passwordConfirm: '',
        birth: isNullOrEmpty(registry.birth) ? undefined : moment(registry.birth),
        active: registry.active ? 'true' : 'false',
      });

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);

      if (!error) setIsVisible(false);
    }
  }, [saving]);

  return (
    <Modal
      title="Editar usuário"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={600}
      processing={isSaving}
      disabled={loading}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <Row gutter={8}>
            <Col xs={18}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Nome é obrigatório' }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>

            <Col xs={6}>
              <Form.Item
                label="Login"
                name="login"
                rules={[{ required: true, message: 'Login é obrigatório' }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[{ required: true, message: 'E-mail é obrigatório' }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Input type="email" />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={7}>
              <Form.Item
                label="Senha"
                name="password"
                rules={[{ required: false }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Input type={isShowPassword ? 'text' : "password"} addonAfter={(
                    <>
                      {!isShowPassword && (<EyeInvisibleOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                      {isShowPassword && (<EyeOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                    </>
                  )} />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={7}>
              <Form.Item
                label="Confirme senha"
                name="passwordConfirm"
                rules={[({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('password') === value || (isNullOrEmpty(getFieldValue('password')) && isNullOrEmpty(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('As senhas não combinam'));
                  },
                })]}
                dependencies={['password']}
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Input type={isShowPassword ? 'text' : "password"} addonAfter={(
                    <>
                      {!isShowPassword && (<EyeInvisibleOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                      {isShowPassword && (<EyeOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                    </>
                  )} />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                label="Nascimento"
                name="birth"
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <DatePicker format="DD/MM/YYYY" placeholder="" />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={4}>
              <Form.Item
                label="Ativo?"
                name="active"
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Select>
                    {listYesNo.map(d => (
                      <Select.Option key={d.key} value={d.key}>{d.value}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal >
  );
}
