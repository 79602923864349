import styled from 'styled-components';
import { Form, Button as _Button } from 'antd';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-v2 {
    width: 90%;
    max-width: 400px;
  }

  button {
    margin-top: 1rem;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 1rem;

  .ant-form-item-label {
    padding: 0;
    margin-bottom: .4rem;
  }

  .ant-form-item-explain {
    margin-top: 0.3rem;
    margin-bottom: -0.6rem;
  }
`;

export const Button = styled(_Button)`
  width: 100%;
`;
