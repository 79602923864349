import styled from 'styled-components';
import { Table as aTable } from 'antd';

export const Table = styled(aTable)`
  th {
    &.ant-table-cell {
      padding: 8px;
      font-size: 13px;
      font-weight: 700;
    }
  }

  td {
    &.ant-table-cell {
      padding: 0;
      padding-left: 9px;
      font-size: 12px;
    }
  }
`;
