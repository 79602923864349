import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Dropdown, Menu, Button, Row, Col, Spin, Tree } from 'antd';

import DataList from '~/components/DataList';
import ModalCreate from './Create';
import ModalUpdate from './Update';

import { categoryIndexRequest, categoryReadRequest, categoryDeleteRequest } from '~/store/modules/category/actions';

import { GroupOutlined, PlusOutlined, EditOutlined, DeleteOutlined, HomeFilled, MoreOutlined } from '@ant-design/icons';
import { MenuButton } from '~/pages/_layouts/default/styles';
import { Container, Content } from './styles';

const { DirectoryTree, TreeNode } = Tree;

export const Breadcumb = [[<HomeFilled title="Dashboard" />, '/'], ['Categorias', '/categories']];

export default function Category() {
  const dispatch = useDispatch();

  const dataSource = useSelector(store => store.category.list);
  const loading = useSelector(store => store.category.loading);

  const [categoryId, setCategoryId] = useState();
  const [categories, setCategories] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);

  function onItemUpdate(id) {
    dispatch(categoryReadRequest(id));
    setIsVisibleUpdate(true);
  }

  function onItemDelete(id) {
    try {
      if (window.confirm("Deseja realmente excluir?")) {
        dispatch(categoryDeleteRequest(id));
      }
    }
    catch (err) {
      //
    }
  }

  const onDragEnter = (info) => {
    console.log(info);
    // expandedKeys 
    // setExpandedKeys(info.expandedKeys)
  };

  const onDrop = (info) => {
    console.log(info);

    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
  };

  useEffect(() => {
    dispatch(categoryIndexRequest());
  }, []);

  useMemo(() => {
    const treeData = dataSource?.map(e => {
      return {
        title: e.name,
        key: e.id,
        active: Number(e.active),
        children: e.Categories?.map(s => {
          return { title: s.name, key: s.id, isLeaf: true, active: Number(s.active), icon: <GroupOutlined /> }
        }),
      };
    });

    setCategories(treeData);
  }, [dataSource]);

  return (
    <Container>
      {loading ? (
        <div className='text-center'>
          <Spin />
        </div>
      ) : (
        <Tree
          selectable={true}
          defaultExpandAll
          loading={loading}
          className="draggable-tree"
          draggable
          blockNode
          onDragEnter={onDragEnter}
          onDrop={onDrop}
        >
          {categories?.map(c => (
            <TreeNode icon={undefined} title={<Content>
              <div className='tree-content-title'>{c.active ? c.title : (<s style={{ color: '#f30' }}>{c.title}</s>)}</div>
              <div className='tree-content-menu'>
                <Dropdown
                  placement="bottomLeft"
                  arrow
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={() => onItemUpdate(c.key)}
                        icon={<EditOutlined />}
                      >Editar</Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => onItemDelete(c.key)}
                        icon={<DeleteOutlined />}
                      >Remover</Menu.Item>
                    </Menu>
                  )} >
                  <MenuButton style={{ border: 'none' }}>
                    <MoreOutlined />
                  </MenuButton>
                </Dropdown>
              </div>
            </Content>} key={c.key}>
              {c.children?.map(s => (
                <TreeNode title={<Content>
                  <div className='tree-content-title'>{s.active ? s.title : (<s style={{ color: '#f30' }}>{s.title}</s>)}</div>
                  <div className='tree-content-menu'>
                    <Dropdown
                      placement="bottomLeft"
                      arrow
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="1"
                            onClick={() => onItemUpdate(s.key)}
                            icon={<EditOutlined />}
                          >Editar</Menu.Item>
                          <Menu.Item
                            key="2"
                            onClick={() => onItemDelete(s.key)}
                            icon={<DeleteOutlined />}
                          >Remover</Menu.Item>
                        </Menu>
                      )} >
                      <MenuButton style={{ border: 'none' }}>
                        <MoreOutlined />
                      </MenuButton>
                    </Dropdown>
                  </div>
                </Content>} key={s.key} isLeaf={true} />
              ))}
            </TreeNode>
          ))}
        </Tree>
      )
      }

      <Space className="btn-fixed">
        <Row>
          <Col xs={24} md={0}>
            <Button loading={loading} type="primary" shape="circle" onClick={() => setIsVisibleCreate(true)} icon={<PlusOutlined />} size="small" style={{ width: 50, height: 50 }} />
          </Col>
          <Col xs={0} md={24}>
            <Button loading={loading} type="primary" shape="circle" onClick={() => setIsVisibleCreate(true)} icon={<PlusOutlined />} size="small" style={{ margin: 20, width: 50, height: 50 }} />
          </Col>
        </Row>
      </Space>

      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} />
      <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} id={categoryId} />
    </Container >
  );
}
