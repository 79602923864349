import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';

import Modal from '~/components/Modal';

import { attributeValueCreateRequest } from '~/store/modules/attributeValue/actions';

export default function Create({ id, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.attributeValue.loading);
  const error = useSelector(store => store.attributeValue.error);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(attributeValueCreateRequest(values, id));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({
      value: '',
      active: 'true',
    });
  }

  useMemo(() => {
    if (isSaving && !loading) {
      setIsSaving(false);

      if (!error) {
        onReset();
        setIsVisible(false);
      }
    }
  }, [loading]);

  return (
    <Modal
      title="Novo valor de atributo"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Valor"
              name="value"
              rules={[{ required: true, message: 'Valor é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
