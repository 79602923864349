import React from 'react';
import PropTypes from 'prop-types';

import { Table } from './styles';

export default function DataList({ children, ...rest }) {
  var { pagination } = { ...rest };

  if (!pagination) {
    pagination = { defaultPageSize: 25 }
  }

  const props = { pagination, ...rest };

  return (
    <Table {...props}>
      {children}
    </Table>
  );
}

DataList.propTypes = {
  children: PropTypes.node.isRequired,
};
