import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  registry: {},
  loading: false,
  error: false
};

export default function attributeValue(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@attributeValues/INDEX_REQUEST':
    case '@attributeValues/CREATE_REQUEST':
    case '@attributeValues/UPDATE_REQUEST':
    case '@attributeValues/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@attributeValues/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
        draft.registry = {};
      });

    case '@attributeValues/CREATE_SUCCESS':
    case '@attributeValues/UPDATE_SUCCESS':
    case '@attributeValues/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@attributeValues/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@attributeValues/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.registry = action.payload.data;
      });

    case '@attributeValues/INDEX_FAILURE':
    case '@attributeValues/CREATE_FAILURE':
    case '@attributeValues/UPDATE_FAILURE':
    case '@attributeValues/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case '@attributeValues/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.registry = {};
      });

    default:
      return state;
  }
}
