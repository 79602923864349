import { takeLatest, call, put, all } from 'redux-saga/effects';

import { apiZipcode as api } from '~/services/api';
import { stringClear } from '~/helpers/util';

import { zipcodeReadSuccess, zipcodeReadFailure } from './actions';

export function* onRead({ payload }) {
  try {
    const { zipcode } = payload;

    const response = yield call(api.get, `/${stringClear(zipcode, "1234567890")}/json/`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Falha ao obter os dados do cep';

    yield put(zipcodeReadSuccess(result));
  }
  catch (err) {
    var message = '';
    if (Array.isArray(err)) {
      err.map(e => `${message}\n${e}`);
    }
    else message = err;

    yield put(zipcodeReadFailure(message));
  }
}

export default all([
  takeLatest('@zipcode/READ_REQUEST', onRead),
]);
