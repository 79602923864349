import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Dropdown, Menu, Button, Row, Col, Table, Avatar } from 'antd';
import moment from 'moment';

import { avatarColors } from '~/helpers/lists';

import DataList from '~/components/DataList';
import ModalCreate from './Create';
import ModalUpdate from './Update';

import { userIndexRequest, userReadRequest, userDeleteRequest } from '~/store/modules/user/actions';

import { MoreOutlined, PlusOutlined, EditOutlined, DeleteOutlined, HomeFilled } from '@ant-design/icons';
import { MenuButton } from '~/pages/_layouts/default/styles';
import { Container } from './styles';

export const Breadcumb = [[<HomeFilled title="Dashboard" />, '/'], ['Usuários', '/users']];

export default function User() {
  const dispatch = useDispatch();

  const dataSource = useSelector(store => store.user.list);
  const loading = useSelector(store => store.user.loading);

  const [userId, setUserId] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);

  const { Column } = Table;

  function onItemUpdate(id) {
    dispatch(userReadRequest(id));
    setIsVisibleUpdate(true);
  }

  function onItemDelete(id) {
    try {
      if (window.confirm("Deseja realmente excluir?")) {
        dispatch(userDeleteRequest(id));
      }
    }
    catch (err) {
      //
    }
  }

  useEffect(() => {
    dispatch(userIndexRequest());
  }, []);


  return (
    <Container>
      <DataList dataSource={dataSource} loading={loading}>
        <Column key="id" dataIndex="id" width={50}
          render={(text, record) => (
            <Space size="middle" className="action">
              <Dropdown
                placement="bottomLeft"
                arrow
                overlay={() => (
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => onItemUpdate(record.id)}
                      icon={<EditOutlined />}
                    >Editar</Menu.Item>
                    <Menu.Item
                      key="2"
                      onClick={() => onItemDelete(record.id)}
                      icon={<DeleteOutlined />}
                    >Remover</Menu.Item>
                  </Menu>
                )} >
                <MenuButton style={{ border: 'none' }}>
                  <MoreOutlined />
                </MenuButton>
              </Dropdown>
            </Space>
          )} />
        <Column align="center" width={44} dataIndex="shortname" key="shortname" render={(_, record) => {
          const avatarColor = avatarColors[moment(record.createdAt).unix() % avatarColors.length];

          const parts = record.name.split(' ');
          let shortName = record.name.substring(0, 1);

          if (parts.length >= 2) shortName = `${shortName}${parts[parts.length - 1].substring(0, 1)}`;
          else if (record.name.length > 1) shortName = `${shortName}${record.name.substring(1, 2)}`;

          return (
            <Avatar data-color={avatarColor} style={{ backgroundColor: avatarColor, verticalAlign: 'middle' }} size="large">
              {shortName.toUpperCase()}
            </Avatar>
          );
        }} />
        <Column title="Nome" align="left" dataIndex="name" key="name" sorter={{
          compare: (a, b) => a.name.localeCompare(b.name)
        }} render={(_, record) => (
          <Space>
            {record.name}
          </Space>
        )} />
        <Column title="Login" align="left" dataIndex="login" key="login" sorter={{
          compare: (a, b) => a.login.localeCompare(b.login)
        }} render={(_, record) => (
          <Space>
            {record.login}
          </Space>
        )} />
        <Column title="E-mail" align="left" dataIndex="email" key="email" sorter={{
          compare: (a, b) => a.email.localeCompare(b.email)
        }} />
        <Column title="Aniversário" align="center" dataIndex="birth" key="birth" sorter={{
          compare: (a, b) => a.birth.localeCompare(b.birth)
        }} render={(_, record) => (
          <Space>
            {!record.birth ? '' : moment(record.birth).format('DD/MM/YYYY')}
          </Space>
        )} />
        <Column title="Último login" align="center" dataIndex="loggedAt" key="loggedAt" sorter={{
          compare: (a, b) => a.loggedAt.localeCompare(b.loggedAt)
        }} render={(_, record) => (
          <Space>
            {!record.loggedAt ? '' : moment(record.loggedAt).format('DD/MM/YYYY HH:mm')}
          </Space>
        )} />
        <Column title="Cadastro" align="center" dataIndex="createdAt" key="createdAt" sorter={{
          compare: (a, b) => a.createdAt.localeCompare(b.createdAt)
        }} render={(_, record) => (
          <Space>
            {moment(record.createdAt).format('DD/MM/YYYY HH:mm')}
          </Space>
        )} />
        <Column title="Ativo?" align="center" dataIndex="active" key="active" sorter={{
          compare: (a, b) => (a.active ? 1 : 0) - (b.active ? 1 : 0)
        }} render={(_, record) => (
          <Space align="center" style={{ color: record.active ? '#090' : '#900' }}>
            {record.active ? 'Sim' : 'Não'}
          </Space>
        )} />
      </DataList>

      <Space className="btn-fixed">
        <Row>
          <Col xs={24} md={0}>
            <Button loading={loading} type="primary" shape="circle" onClick={() => setIsVisibleCreate(true)} icon={<PlusOutlined />} size="small" style={{ width: 50, height: 50 }} />
          </Col>
          <Col xs={0} md={24}>
            <Button loading={loading} type="primary" shape="circle" onClick={() => setIsVisibleCreate(true)} icon={<PlusOutlined />} size="small" style={{ margin: 20, width: 50, height: 50 }} />
          </Col>
        </Row>
      </Space>

      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} />
      <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} id={userId} />
    </Container>
  );
}
