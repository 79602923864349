import styled from 'styled-components';
import { Form, Input as _input, Button as _button } from 'antd';

import bg from '~/assets/images/login_bg.jpg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #010318;
  background: url(${bg});
  background-size: cover;
  background-position: center center;
  font-family: 'Open Sans', sans-serif;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  padding: 20px;
  margin: 0 auto;

  .login-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 1.2rem;
    padding-bottom: 2rem;

    svg {
      margin-left: 1rem;
      font-size: 4rem;
    }

    .brand {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0;
      font-size: 2rem;
      font-weight: bold;

      small {
        font-size: 1.3rem;
        font-weight: normal;
      }
    }
  }

  .logo {
    border: 14px solid transparent;
    border-color: #ffb62c #cd8601 #815502;
    width: 28px;
    height: 28px;
    position: relative;
    font-size: 0;
    margin-right: 10px;
    top: -9px;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2.4rem;
    background: rgba(0,0,0,.4);
    border-radius: 4px;
  }
  
  form {
    .form-control {
      margin-bottom: 1.2rem;
    }

    input {
      background: rgba(0,0,0,.4);
      border: none;
      color: #fff;
    }
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 10px;
`;

export const Input = styled(_input)`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
`;

export const Button = styled(_button)`
  margin-top: 15px;
  margin-bottom: 10px;
`;
