import styled from 'styled-components';

export const Container = styled.div`
  .ant-tree-switcher {
    padding-top: 7px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;

  .tree-content-menu {
    width: 50px;
    text-align: center;
  }

  .tree-content-title {
    flex: 1;
  }
`;