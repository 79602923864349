import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { login, password } = payload;

    const formData = new FormData();

    formData.append('login', login);
    formData.append('password', password);

    const response = yield call(api.post, 'login', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const { token } = result.data;

    const user = {
      id: result.data.id,
      name: result.data.name,
      login: result.data.login,
      email: result.data.email,
      active: result.data.active,
      createdAt: result.data.createdAt,
      loggedAt: result.data.loggedAt,
      level: result.data.level
    };

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/');
  }
  catch (err) {
    toast.error(String(err));
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  if (!payload) return;

  const { token, profile } = payload.auth;

  if (!token || !profile) {
    yield put(signFailure());
    return;
  }

  api.defaults.headers.Authorization = `Bearer ${token}`;

  yield put(signInSuccess(token, profile));
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
]);