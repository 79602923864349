import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, DatePicker } from 'antd';

import Modal from '~/components/Modal';

import { userCreateRequest } from '~/store/modules/user/actions';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

export default function Create({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.user.loading);
  const error = useSelector(store => store.user.error);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(userCreateRequest(values));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({
      name: '',
      login: '',
      email: '',
      password: '',
      passwordConfirm: '',
      birth: undefined,
      active: 'true',
    });
  }

  useEffect(() => {
  }, []);

  useMemo(() => {
    if (isSaving && !loading) {
      setIsSaving(false);

      if (!error) {
        onReset();
        setIsVisible(false);
      }
    }
  }, [loading]);

  return (
    <Modal
      title="Novo usuário"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={600}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={18}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={6}>
            <Form.Item
              label="Login"
              name="login"
              rules={[{ required: true, message: 'Login é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: 'E-mail é obrigatório' }]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label="Senha"
              name="password"
              rules={[{ required: true, message: 'Senha é obrigatória' }]}
            >
              <Input type={isShowPassword ? 'text' : "password"} addonAfter={(
                <>
                  {!isShowPassword && (<EyeInvisibleOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                  {isShowPassword && (<EyeOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                </>
              )} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label="Confirme senha"
              name="passwordConfirm"
              rules={[{ required: true, message: 'Confirmação é obrigatório' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As senhas não combinam'));
                },
              })]}
              dependencies={['password']}
            >
              <Input type={isShowPassword ? 'text' : "password"} addonAfter={(
                <>
                  {!isShowPassword && (<EyeInvisibleOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                  {isShowPassword && (<EyeOutlined style={{ cursor: 'pointer' }} onClick={() => setIsShowPassword(!isShowPassword)} />)}
                </>
              )} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label="Nascimento"
              name="birth"
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
