import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import {
  attributeIndexRequest, attributeIndexSuccess, attributeIndexFailure, attributeCreateSuccess, attributeCreateFailure,
  attributeReadSuccess, attributeReadFailure, attributeUpdateSuccess, attributeUpdateFailure, attributeDeleteSuccess,
  attributeDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    let { filters } = payload;

    if (!filters) filters = [];
    filters.push({ deleted: 0 });

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `attributes${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeIndexSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('name', values.name);

    const response = yield call(api.post, 'attributes', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeCreateSuccess(data));
    yield put(attributeIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `attributes/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeReadSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('active', values.active === 'true' ? 1 : 0);

    const response = yield call(api.post, `attributes/${values.id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeUpdateSuccess(data));
    yield put(attributeIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `attributes/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(attributeDeleteSuccess());
    yield put(attributeIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeDeleteFailure());
  }
}

export default all([
  takeLatest('@attributes/INDEX_REQUEST', onIndex),
  takeLatest('@attributes/CREATE_REQUEST', onCreate),
  takeLatest('@attributes/READ_REQUEST', onRead),
  takeLatest('@attributes/UPDATE_REQUEST', onUpdate),
  takeLatest('@attributes/DELETE_REQUEST', onDelete),
]);
