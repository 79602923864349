import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import attribute from './attribute/sagas';
import attributeValue from './attributeValue/sagas';
import category from './category/sagas';
import product from './product/sagas';
import user from './user/sagas';
import zipcode from './zipcode/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    attribute,
    attributeValue,
    category,
    product,
    user,
    zipcode
  ]);
}
