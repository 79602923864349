import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select } from 'antd';

import Modal from '~/components/Modal';

import { categoryCreateRequest } from '~/store/modules/category/actions';

export default function Create({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.category.loading);
  const error = useSelector(store => store.category.error);
  const dataSource = useSelector(store => store.category.list);

  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(categoryCreateRequest(values));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({
      name: '',
      categoryId: undefined
    });
  }

  useMemo(() => {
    if (isSaving && !loading) {
      setIsSaving(false);

      if (!error) {
        onReset();
        setIsVisible(false);
      }
    }
  }, [loading]);

  return (
    <Modal
      title="Adicionar categoria"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Superior"
              name="categoryId"
            >
              <Select>
                <Select.Option key="categoryId-null" value="">Sem categoria</Select.Option>
                {dataSource?.map(d => (
                  <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
