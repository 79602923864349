import React from 'react';

import { HomeFilled } from '@ant-design/icons';
import { Container } from './styles';

export const Breadcumb = [[<HomeFilled title="Dashboard" />, '/']];

export default function Home() {
  return (
    <Container>
      Em breve, insights dos registros.
    </Container>
  );
}
