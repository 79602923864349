import React, { useState, useEffect, useMemo } from 'react';
import { MdNotifications } from 'react-icons/md';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '~/services/api';
import history from '~/services/history';
import { isNullOrEmpty } from '~/helpers/util';

import { Container, Badge, NotificationList, Scroll, Notification, ContainerBackground } from './styles';

export default function Notifications() {
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [hasUnread, setHasUnread] = useState(false);

  async function loadNotification() {
    const response = await api.get('notifications');

    if (!response || !response.data || !response.data.success || !response.data.data) return;

    const data = response.data.data.map(notification => ({
      ...notification,
      timeDistance: formatDistance(
        parseISO(notification.createdAt),
        new Date(),
        { addSuffix: true, locale: pt }
      )
    }));

    setNotifications(data);
  }

  function handleToggleVisible() {
    setVisible(!visible);
  }

  async function onClick(id, url) {
    if (!isNullOrEmpty(url)) history.push(url);

    await api.post(`notifications/${id}`);

    setNotifications(
      notifications.map(notification =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  }

  useEffect(() => {
    // setInterval(loadNotification, 15000);
    loadNotification();
  }, []);

  useMemo(() => {
    const unread = !!notifications.find(notification => Number(notification.read) === 0);
    setHasUnread(unread);
  }, [notifications]);

  return (
    <Container>
      <Badge onClick={handleToggleVisible} hasUnread={hasUnread}>
        {hasUnread}
        <MdNotifications color="rgba(255,255,255,0.65)" size={30} />
      </Badge>

      <ContainerBackground visible={visible} onClick={handleToggleVisible} />
      <NotificationList visible={visible}>
        <Scroll>
          {notifications.map(notification => (
            <Notification key={notification.id} unread={!notification.read} onClick={() => onClick(notification.id, notification.url)}>
              <p dangerouslySetInnerHTML={{ __html: notification.description }}></p>
              <time>{notification.timeDistance}</time>
            </Notification>
          ))}
        </Scroll>
      </NotificationList>
    </Container >
  );
}
