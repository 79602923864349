import styled, { css, keyframes } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';

const pulse_animation = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(10, 10, 10, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(10, 10, 10, 0);
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  animation: ${props => props.hasUnread && pulse_animation} 2s infinite;

  ${props => props.hasUnread && css`
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      width: 8px;
      height: 8px;
      background: #ff892e;
      content: '';
      border-radius: 50%;
      animation: ${pulse_animation} 2s infinite;
    }
  `}
`;

export const ContainerBackground = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 9998;
`;

export const NotificationList = styled.div`
  position: absolute;
  width: 260px;
  left: calc(50% - 130px);
  top: calc(100% + 10px);
  background: ${lighten(0.1, '#001529')};
  border-radius: 4px;
  padding: 15px 5px;
  display: ${props => (props.visible ? 'block' : 'none')};
  z-index: 9999;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${lighten(0.1, '#001529')};
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 70vh;
  padding: 5px 15px;
`;

export const Notification = styled.div`
  cursor: pointer;
  color: #fff;
  font-weight: ${props => props.unread ? 'bold' : 'normal'};

  & +  div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(255,255,255,0.1);
  }

  p {
    font-size: 13px;
    line-height: 18px;
  }

  time {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    color: ${lighten(0.2, '#717171')};

    ${props => props.unread && css`
      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #ff892e;
        border-radius: 50%;
        margin-left: 10px;
      }
    `}
  }

  button {
    font-size: 12px;
    border: 0;
    background: none;
    color: ${lighten(0.2, '#717171')};
  }
`;