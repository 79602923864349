import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import {
  userIndexRequest, userIndexSuccess, userIndexFailure, userCreateSuccess, userCreateFailure, userReadSuccess,
  userReadFailure, userUpdateSuccess, userUpdateFailure, userDeleteSuccess, userDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    let { filters } = payload;

    if (!filters) filters = [];
    filters.push({ deleted: 0 });

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `users${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userIndexSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(userIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('login', values.login);
    formData.append('email', values.email);
    formData.append('confirmEmail', values.email);
    formData.append('password', values.password);
    formData.append('confirmPassword', values.passwordConfirm);
    if (!isNullOrEmpty(values.birth)) formData.append('birth', moment(values.birth).format('YYYY-MM-DD'));

    const response = yield call(api.post, 'users', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userCreateSuccess(data));
    yield put(userIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(userCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `users/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userReadSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(userReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('login', values.login);
    formData.append('email', values.email);
    formData.append('confirmEmail', values.email);
    if (!isNullOrEmpty(values.password)) formData.append('password', values.password);
    if (!isNullOrEmpty(values.password)) formData.append('confirmPassword', values.passwordConfirm);
    if (!isNullOrEmpty(values.birth)) formData.append('birth', moment(values.birth).format('YYYY-MM-DD'));
    formData.append('active', values.active === 'true' ? 1 : 0);

    const response = yield call(api.post, `users/${values.id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userUpdateSuccess(data));
    yield put(userIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(userUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `users/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(userDeleteSuccess());
    yield put(userIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(userDeleteFailure());
  }
}

export default all([
  takeLatest('@users/INDEX_REQUEST', onIndex),
  takeLatest('@users/CREATE_REQUEST', onCreate),
  takeLatest('@users/READ_REQUEST', onRead),
  takeLatest('@users/UPDATE_REQUEST', onUpdate),
  takeLatest('@users/DELETE_REQUEST', onDelete),
]);
