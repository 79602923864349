import styled from 'styled-components';
import { Collapse, Divider } from 'antd';

export const Container = styled.div`
  
`;

export const Frame = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
  .ant-collapse-header{
    display: flex;
    padding-bottom: 0 !important;
  }
`;

export const FrameDivider = styled(Divider)`  
  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 2% !important;
    top: 0% !important;
  }
  &.ant-divider-horizontal.ant-divider-with-text-left::after{
    top: 0% !important;
  }
  border-top-color: #D9D9D9 !important;
  margin-top: 0 !important;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  transition: height 0.2 ease;
`;
