import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input } from 'antd';

import { signInRequest } from '~/store/modules/auth/actions';

import { LoginOutlined } from '@ant-design/icons';
import { Container, FormItem, Button } from './styles';

export default function SignIn() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.auth.loading);

  const [form] = Form.useForm();

  function onFinish(values) {
    if (!loading) {
      const { login, password } = values;
      dispatch(signInRequest(login, password));
    }
  }

  return (
    <Container>
      <div class="fade in login login-v2">
        <div class="login-header">
          <div class="brand">
            <div>
              <span class="logo"></span> Pets 7 Friends
            </div>

            <small>Backoffice</small>
          </div>

          <div class="icon">
            <LoginOutlined />
          </div>
        </div>

        <div class="login-content">
          <Form
            layout='vertical'
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24}>
                <FormItem
                  name="login"
                  label="Login ou E-mail"
                  required
                >
                  <Input />
                </FormItem>
              </Col>

              <Col xs={24}>
                <FormItem
                  name="password"
                  label="Senha"
                  required
                >
                  <Input type="password" />
                </FormItem>
              </Col>

              <Col xs={24}>
                <Button type='primary' htmlType='submit' loading={loading}>Entrar</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Container >
  );
}
