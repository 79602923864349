import axios from 'axios';
import * as dotenv from 'dotenv';

import { store } from '~/store';
import history from '~/services/history';

import { signFailure } from '~/store/modules/auth/actions';

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';

api.interceptors.response.use((response) => {
  const state = store.getState();

  return response;
}, (error) => {
  console.log({ error });
  if (error?.response?.status == 401 || error?.response?.status == 403) {
    store.dispatch(signFailure())
  }

  return error;
});

export const apiZipcode = axios.create({
  baseURL: process.env.REACT_APP_ZIPCODE_URL
});

export default api;
