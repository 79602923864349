import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Skeleton, Space, Menu, Dropdown, Table, Button } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';
import DataList from '~/components/DataList';

import ModalCreate from '../../AttributeValue/Create';
import ModalUpdate from '../../AttributeValue/Update';

import { listYesNo } from '~/helpers/lists';
import { isNullOrEmpty } from '~/helpers/util';

import { attributeUpdateRequest } from '~/store/modules/attribute/actions';
import { attributeValueIndexRequest, attributeValueReadRequest, attributeValueDeleteRequest } from '~/store/modules/attributeValue/actions';

import { MoreOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { MenuButton } from '~/pages/_layouts/default/styles';
import { Container } from './styles';

export default function Update({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const saving = useSelector(store => store.attribute.loading);
  const error = useSelector(store => store.attribute.error);
  const registry = useSelector(store => store.attribute.registry);

  const dataSource = useSelector(store => store.attributeValue.list);
  const dataLoading = useSelector(store => store.attributeValue.loading);

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);

  const [form] = Form.useForm();

  const { Column } = Table;

  function onItemUpdate(id) {
    dispatch(attributeValueReadRequest(id, registry?.id));
    setIsVisibleUpdate(true);
  }

  function onItemDelete(id) {
    try {
      if (window.confirm("Deseja realmente excluir?")) {
        dispatch(attributeValueDeleteRequest(id, registry?.id));
      }
    }
    catch (err) {
      //
    }
  }

  function onFinish(values) {
    dispatch(attributeUpdateRequest(values));
    setIsSaving(true);
  }

  useEffect(() => {
    setLoading(true);
  }, []);

  useMemo(() => {
    setLoading(true);

    if (!isNullOrEmpty(registry?.id)) {
      form.setFieldsValue({
        id: registry.id,
        name: registry.name,
        active: registry.active ? 'true' : 'false',
      });

      dispatch(attributeValueIndexRequest(registry?.id));

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);

      if (!error) setIsVisible(false);
    }
  }, [saving]);

  return (
    <Modal
      title="Editar atributo"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={600}
      processing={isSaving}
      disabled={loading}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <Row gutter={8}>
            <Col xs={24} md={19}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Nome é obrigatório' }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={5}>
              <Form.Item
                label="Ativo?"
                name="active"
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Select>
                    {listYesNo.map(d => (
                      <Select.Option key={d.key} value={d.key}>{d.value}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <hr />

        <Row>
          <Col xs={24} className='text-right'>
            <Space className='m-t-8 m-b-8'>
              <Button htmlType='button' type='primary'
                icon={<PlusOutlined />}
                loading={loading || dataLoading}
                onClick={() => setIsVisibleCreate(true)}
              >Adicionar valor</Button>
            </Space>
          </Col>
        </Row>

        {loading ? (
          <Skeleton.Button active size="small" block />
        ) : (
          <DataList dataSource={dataSource} loading={dataLoading}>
            <Column key="id" dataIndex="id" width={50}
              render={(text, record) => (
                <Space size="middle" className="action">
                  <Dropdown
                    placement="bottomLeft"
                    arrow
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key="1"
                          onClick={() => onItemUpdate(record.id)}
                          icon={<EditOutlined />}
                        >Editar</Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={() => onItemDelete(record.id)}
                          icon={<DeleteOutlined />}
                        >Remover</Menu.Item>
                      </Menu>
                    )} >
                    <MenuButton style={{ border: 'none' }}>
                      <MoreOutlined />
                    </MenuButton>
                  </Dropdown>
                </Space>
              )} />
            <Column title="Valor" align="left" dataIndex="value" key="value" sorter={{
              compare: (a, b) => a.value.localeCompare(b.value)
            }} render={(_, record) => (
              <Space>
                {record.value}
              </Space>
            )} />
            <Column width={40} title="Ativo?" align="center" dataIndex="active" key="active" sorter={{
              compare: (a, b) => (a.active ? 1 : 0) - (b.active ? 1 : 0)
            }} render={(_, record) => (
              <Space align="center" style={{ color: record.active ? '#090' : '#900' }}>
                {record.active ? 'Sim' : 'Não'}
              </Space>
            )} />
            <Column width={150} title="Cadastro" align="center" dataIndex="createdAt" key="createdAt" sorter={{
              compare: (a, b) => a.createdAt.localeCompare(b.createdAt)
            }} render={(_, record) => (
              <Space>
                {moment(record.createdAt).format('DD/MM/YYYY HH:mm')}
              </Space>
            )} />
          </DataList>
        )}

        <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} id={registry?.id} />
        <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} />
      </Container>
    </Modal >
  );
}
