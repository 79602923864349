import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import history from '~/services/history';

import { UsergroupAddOutlined, PieChartOutlined, GroupOutlined, FileProtectOutlined, GiftFilled } from '@ant-design/icons';
import { Container, Item } from './styles';

export default function Menu({ breadcrumb, isOpen, showMenu }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);

  const [selectedMenu, setSelectedMenu] = useState();

  const menuItens = [
    { key: 1, level: 5, title: 'Dashboard', icon: (<PieChartOutlined />), url: '/' },
    { key: 2, level: 5, title: 'Categorias', icon: (<GroupOutlined />), url: '/categories' },
    { key: 3, level: 5, title: 'Atributos de Produto', icon: (<FileProtectOutlined />), url: '/attributes' },
    { key: 4, level: 5, title: 'Produtos', icon: (<GiftFilled />), url: '/products' },
    { key: 5, level: 7, title: 'Usuários', icon: (<UsergroupAddOutlined />), url: '/users' },
  ];

  function menuClick(url) {
    if (isOpen) showMenu(!isOpen);

    history.push(url);
  }

  useEffect(() => {
    const menu = menuItens.find(a => a.breadcrumb === breadcrumb);
    if (menu) setSelectedMenu(menu.key);
  }, [breadcrumb]);

  return (
    <Container
      selectedKeys={selectedMenu}
      mode="inline"
    >
      <div className="logo"></div>

      {menuItens.map(m => {
        if (profile.level >= m.level) {
          return (
            <Item key={m.key} icon={m.icon} onClick={() => menuClick(m.url)}>
              {m.title}
            </Item>
          );
        }
      })}
    </Container>
  );
}
