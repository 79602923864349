export function zipcodeReadRequest(zipcode) {
  return {
    type: '@zipcode/READ_REQUEST',
    payload: { zipcode },
  };
}

export function zipcodeReadSuccess(data) {
  return {
    type: '@zipcode/READ_SUCCESS',
    payload: { data },
  };
}

export function zipcodeReadFailure(error) {
  return {
    type: '@zipcode/READ_FAILURE',
    payload: { error },
  };
}
