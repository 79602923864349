import styled from 'styled-components';
import { darken } from 'polished';
import {
  Layout as aLayout,
  Form as aForm,
  Input as aInput,
  Button as aButton,
  Drawer as aDrawer
} from 'antd';

export const Container = styled(aLayout)`
  min-height: 100vh;

  .logo {
    height: 32px;
    margin: 16px;

    img {
      height: 28px;
    }
  }
`;

export const Layout = styled(aLayout)`
  background: #fff;

  .site-layout-background {
    &.isMobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const Drawer = styled(aDrawer)`
  .ant-drawer-body {
    background-color: var(--header-background);
    padding: 0;
  }
`;

export const Sider = styled(aLayout.Sider)`
  height: auto;
  background-color: var(--header-background);

  .ant-layout-sider-trigger {
    background-color: ${darken(0.02, '#001529')};
  }

  .ant-menu-inline-collapsed::before {
    display: unset;
  }  
`;

export const Form = styled(aForm)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const Input = styled(aInput)`
  background: rgba(255, 255, 255, 0.8);
  border: 0;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  color: #222;
  margin: 0 0 10px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const Button = styled(aButton)`
  margin: 5px 0 0;
  height: 44px;
  background: #3b9eff;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
`;

export const MenuButton = styled(aButton)`
  margin: 5px 0 0;
  font-weight: bold;
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 0;
  font-size: 16px;
  transition: background 0.2s;
`;
