import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Col, Avatar, Row } from 'antd';
import moment from 'moment';

import Notifications from '~/components/Notifications';

import history from '~/services/history';
import { avatarColors, listLevels } from '~/helpers/lists';

import { signFailure } from '~/store/modules/auth/actions';

import { UserOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Container, Content, MenuIcon, Dropdown, Menu, UserInfo, UserPerfil, UserName, UserMenu } from './styles';

export default function Header({ isOpen, showMenu }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);

  const avatarColor = avatarColors[moment(profile?.createdAt).unix() % avatarColors.length];

  const parts = profile?.name?.split(' ');
  let shortName = profile?.name?.substring(0, 1);

  if (parts.length >= 2) shortName = `${shortName}${parts[parts.length - 1].substring(0, 1)}`;
  else if (profile?.name?.length > 1) shortName = `${shortName}${profile?.name?.substring(1, 2)}`;

  return (
    <Container>
      <Content>
        {isMobile && (
          <Col xs={8}>
            <MenuIcon onClick={() => showMenu(!isOpen)} />
          </Col>
        )}

        <div></div>

        <Row align="center" justify="space-between" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Notifications />

          <Dropdown
            placement="bottomRight"
            overlay={() => (
              <UserMenu>
                <div className='user-menu-item'>
                  <Avatar size={90} data-color={avatarColor} style={{
                    backgroundColor: avatarColor,
                    verticalAlign: 'middle'
                  }}>
                    <span style={{ fontSize: 32 }}>{shortName.toUpperCase()}</span>
                  </Avatar>

                  <p>{profile?.name}</p>
                  <UserPerfil>{listLevels.find(a => a.key === profile?.level)?.value}</UserPerfil>
                </div>
                <div className='user-menu-item'>
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => { }}
                      icon={<UserOutlined />}
                    >Gerenciar minha conta</Menu.Item>
                    <Menu.Item
                      key="3"
                      onClick={() => { if (window.confirm('Deseja realmente sair?')) { dispatch(signFailure()); history.push('/'); } }}
                      icon={<PoweroffOutlined />}
                    >Sair</Menu.Item>
                  </Menu>
                </div>
              </UserMenu>
            )}>
            <Content>
              <Avatar size="large" data-color={avatarColor} style={{ backgroundColor: avatarColor, verticalAlign: 'middle' }}>
                {shortName.toUpperCase()}
              </Avatar>
              <UserInfo xs={0} sm={24}>
                <UserName>{parts[0]}</UserName>
              </UserInfo>
            </Content>
          </Dropdown>
        </Row>
      </Content>
    </Container >
  );
}
