import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import {
  attributeValueIndexRequest, attributeValueIndexSuccess, attributeValueIndexFailure, attributeValueCreateSuccess,
  attributeValueCreateFailure, attributeValueReadSuccess, attributeValueReadFailure, attributeValueUpdateSuccess,
  attributeValueUpdateFailure, attributeValueDeleteSuccess, attributeValueDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    let { id, filters } = payload;

    if (!filters) filters = [];
    filters.push({ deleted: 0 });

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `attributes/${id}/values${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeValueIndexSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeValueIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { attributeId } = payload;
    const values = payload.data;

    const formData = new FormData();

    formData.append('value', values.value);

    const response = yield call(api.post, `attributes/${attributeId}/values`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeValueCreateSuccess(data));
    yield put(attributeValueIndexRequest(attributeId));

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeValueCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id, attributeId } = payload;

    const response = yield call(api.get, `attributes/${attributeId}/values/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeValueReadSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeValueReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('value', values.value);
    formData.append('active', values.active === 'true' ? 1 : 0);

    const response = yield call(api.post, `attributes/${values.attributeId}/values/${values.id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(attributeValueUpdateSuccess(data));
    yield put(attributeValueIndexRequest(values.attributeId));

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeValueUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id, attributeId } = payload;

    const response = yield call(api.delete, `attributes/${attributeId}/values/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(attributeValueDeleteSuccess());
    yield put(attributeValueIndexRequest(attributeId));

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(attributeValueDeleteFailure());
  }
}

export default all([
  takeLatest('@attributeValues/INDEX_REQUEST', onIndex),
  takeLatest('@attributeValues/CREATE_REQUEST', onCreate),
  takeLatest('@attributeValues/READ_REQUEST', onRead),
  takeLatest('@attributeValues/UPDATE_REQUEST', onUpdate),
  takeLatest('@attributeValues/DELETE_REQUEST', onDelete),
]);
