import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Switch, Spin, Space, Button, Table } from 'antd';
import ReactQuill from 'react-quill';
import Dropzone from 'react-dropzone';
import filesize from 'filesize';
import { uniqueId } from 'lodash';

import DataList from '~/components/DataList';

import { money, onlyNumber, isNullOrEmpty } from '~/helpers/util';

import { attributeIndexRequest } from '~/store/modules/attribute/actions';
import { categoryIndexRequest } from '~/store/modules/category/actions';
import { productCreateRequest } from '~/store/modules/product/actions';

import 'react-quill/dist/quill.snow.css';
import { HomeFilled, UploadOutlined, DeleteFilled } from '@ant-design/icons';
import { Container, Frame, FrameDivider, DropContainer } from './styles';

export const Breadcumb = [[<HomeFilled title="Dashboard" />, '/'], ['Produtos', '/products'], ['Novo produto']];

export default function ProductCreate() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.product.loading);
  const error = useSelector(store => store.product.error);

  const categories = useSelector(store => store.category.list);
  const categoriesLoading = useSelector(store => store.category.loading);

  const attributes = useSelector(store => store.attribute.list);
  const attributesLoading = useSelector(store => store.attribute.loading);

  const [isSaving, setIsSaving] = useState(false);
  const [attrChecked, setAttrChecked] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [form] = Form.useForm();
  const { Column } = Table;

  let myfiles = [];

  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
      ['clean']
    ],
  };

  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align',
    'list', 'bullet', 'indent',
    'link'
  ];

  function onFinish(values) {
    console.log({ uploadedFiles });

    if (!uploadedFiles || uploadedFiles.length <= 0) {
      form.setFields([
        {
          name: 'photos',
          errors: ['Ao menos uma foto deve ser informada'],
        },
      ]);
      return;
    }

    const formData = new FormData();
    formData.encType = 'multipart/form-data';

    const keys = Object.keys(values);

    for (let i = 0; i < keys.length; i++) {
      if (!isNullOrEmpty(values[keys[i]])) {
        formData.append(keys[i], values[keys[i]]);
      }
    };

    for (let i = 0; i < uploadedFiles.length; i++) {
      formData.append(`file_${i}`, JSON.stringify(uploadedFiles[i]));
    };

    dispatch(productCreateRequest(formData));
    setIsSaving(true);
  }

  function onAttributeChange(attrId, checked) {
    form.setFieldValue(attrId, checked);

    if (!checked) form.setFieldValue(`attr_${attrId}`, '');

    const whiteList = attrChecked?.filter(i => i.id !== attrId);
    whiteList.push({ id: attrId, checked: form.getFieldValue(attrId) });
    setAttrChecked(whiteList);
  }

  function onUpload(files) {
    const upFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      message: '',
      dateUpload: undefined,
      base64: '',
      fileKey: ''
    }));

    myfiles = uploadedFiles.concat(upFiles);

    setUploadedFiles(myfiles);

    myfiles.forEach(processUpload);
  };

  function updateFile(id, data) {
    const result = myfiles.map(file => {
      return id === file.id ? { ...file, ...data } : file;
    });

    setUploadedFiles(result);
    myfiles = result;
  };

  function deleteFile(id) {
    if (window.confirm('Deseja remover esta foto?')) {
      const result = uploadedFiles.filter(file => id !== file.id);

      setUploadedFiles(result);
      myfiles = result;
    }
  };

  function processUpload(uploadedFile) {
    if (!uploadedFile.uploaded) {
      const reader = new FileReader();

      reader.onloadend = () => {
        updateFile(uploadedFile.id, {
          uploaded: true,
          id: uploadedFile.id,
          base64: reader.result
        });
      };

      reader.readAsDataURL(uploadedFile.file);
    }
  }

  useEffect(() => {
    dispatch(categoryIndexRequest([{ active: 1 }]));
    dispatch(attributeIndexRequest([{ active: 1 }]));
  }, []);

  return (
    <Container>
      <h1>Cadastrar novo produto</h1>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish} encType="multipart/form-data">
        <Frame defaultActiveKey={['1', '2', '3']} ghost>
          <Frame.Panel header={<FrameDivider orientation="left">Dados cadastrais</FrameDivider>} key="1">
            <Row gutter={8}>
              <Col xs={24} md={18} xl={8}>
                <Form.Item
                  label="Categoria"
                  name="categoryId"
                >
                  <Select loading={categoriesLoading}>
                    <Select.Option key="0" value="">Sem categoria</Select.Option>
                    {categories?.map(c => (
                      <>
                        <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
                        {c.Categories?.map(s => (
                          <Select.Option key={s.id} value={s.id}>{c.name} / {s.name}</Select.Option>
                        ))
                        }
                      </>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={6} xl={3}>
                <Form.Item
                  label="Código"
                  name="code"
                  rules={[{ required: true, message: 'Código é obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} xl={10}>
                <Form.Item
                  label="Nome"
                  name="name"
                  rules={[{ required: true, message: 'Nome é obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={8} xl={3}>
                <Form.Item
                  label="Preço"
                  name="price"
                  rules={[{ required: true, message: 'Preço é obrigatório' },
                  () => ({
                    validator(_, value) {
                      if (!value) return Promise.resolve();
                      if (Number(value) < 0) Promise.reject(new Error('Preço inválido'));
                      else {
                        const _split = `${value}`.replace('.', '').split(',');

                        if (_split.length > 2) return Promise.reject(new Error('Preço inválido'));

                        const _p1 = _split[0];
                        const _p2 = _split[1];

                        if (_p2 && _p2.length > 2) return Promise.reject(new Error('Preço inválido'));

                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Preço inválido'));
                    },
                  })]}
                >
                  <Input type="number" onKeyDown={(e) => money(e, true)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={6} xl={3}>
                <Form.Item
                  label="Peso(g)"
                  name="weight"
                >
                  <Input onKeyDown={(e) => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={5} xl={3}>
                <Form.Item
                  label="Largura(mm)"
                  name="width"
                >
                  <Input onKeyDown={(e) => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={5} xl={3}>
                <Form.Item
                  label="Altura(mm)"
                  name="height"
                >
                  <Input onKeyDown={(e) => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={5} xl={3}>
                <Form.Item
                  label="Compri.(mm)"
                  name="length"
                >
                  <Input onKeyDown={(e) => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={5} xl={3}>
                <Form.Item
                  label="Estoque"
                  name="stock"
                >
                  <Input type="number" onKeyDown={e => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={5} xl={3}>
                <Form.Item
                  label="Estoque min"
                  name="stockMinimal"
                  rules={[{ required: true, message: 'Estoque mínimo é obrigatório' }]}
                >
                  <Input type="number" onKeyDown={e => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={9} xl={6}>
                <Form.Item
                  label="Código barras"
                  name="ean"
                >
                  <Input onKeyDown={(e) => onlyNumber(e)} />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  label="Descrição"
                  name="description"
                  rules={[{ required: true, message: 'Descrição é obrigatória' }]}
                >
                  <ReactQuill theme="snow" modules={editorModules} formats={editorFormats} />
                </Form.Item>
              </Col>
            </Row>
          </Frame.Panel>

          <Frame.Panel header={<FrameDivider orientation="left">Fotos</FrameDivider>} key="2">
            <Row gutter={8}>
              <Col xs={24}>
                <Form.Item name="photos">
                  <Dropzone noClick onDropAccepted={onUpload}>
                    {({ getRootProps, open, getInputProps, isDragActive, isDragReject }) => (
                      <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                      >
                        <input {...getInputProps()} />

                        <div className='text-right w-100 m-b-8'>
                          <Button icon={<UploadOutlined />} onClick={() => { open() }} htmlType='button'>Adicionar fotos</Button>
                        </div>
                      </DropContainer>
                    )}
                  </Dropzone>

                  <DataList dataSource={uploadedFiles}>
                    <Column key="id" dataIndex="id" width={50}
                      render={(text, record) => (
                        <Space size="middle" className="action">
                          <Button icon={<DeleteFilled />}
                            onClick={() => deleteFile(record.id)}
                            htmlType='button'
                            type='primary'
                            danger
                          />
                        </Space>
                      )} />
                    <Column title="Nome" align="left" dataIndex="name" key="name" sorter={{
                      compare: (a, b) => a.name.localeCompare(b.name)
                    }} render={(_, record) => (
                      <Space>
                        {record.name}
                      </Space>
                    )} />
                  </DataList>
                </Form.Item>
              </Col>
            </Row>
          </Frame.Panel>

          <Frame.Panel header={<FrameDivider orientation="left">SKU</FrameDivider>} key="3">
            <Row gutter={8}>
              {attributesLoading ? <Spin /> : (
                <>
                  {attributes?.map(a => (
                    <>
                      <Col xs={12} className="m-t-8">
                        <Form.Item
                          key={a.id}
                          noStyle
                          name={`${a.id}`}
                        >
                          <Switch onChange={e => onAttributeChange(a.id, e)} /> {a.name}
                        </Form.Item>
                      </Col>

                      <Col xs={12} className="m-t-8">
                        <Form.Item
                          key={a.id}
                          name={`attr_${a.id}`}
                        >
                          <Select disabled={attrChecked?.find(i => i.id === a.id)?.checked !== true} defaultValue="">
                            <Select.Option key="0" value="">Não informado</Select.Option>
                            {a.AttributeValue?.map(v => (
                              <Select.Option value={v.id}>{v.value}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  ))}
                </>)}
            </Row>
          </Frame.Panel>
        </Frame>

        <Space style={{ padding: 24, display: 'flex', justifyContent: 'flex-end' }}>
          <Button loading={loading} type="primary" htmlType='submit'>Salvar</Button>
        </Space>
      </Form>
    </Container>
  );
}
