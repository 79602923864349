export function productIndexRequest(filters) {
  return {
    type: '@products/INDEX_REQUEST',
    payload: { filters },
  };
}

export function productIndexSuccess(data) {
  return {
    type: '@products/INDEX_SUCCESS',
    payload: { data },
  };
}

export function productIndexFailure() {
  return {
    type: '@products/INDEX_FAILURE',
  };
}

export function productCreateRequest(data) {
  return {
    type: '@products/CREATE_REQUEST',
    payload: { data },
  };
}

export function productCreateSuccess(data) {
  return {
    type: '@products/CREATE_SUCCESS',
    payload: { data },
  };
}

export function productCreateFailure() {
  return {
    type: '@products/CREATE_FAILURE',
  };
}

export function productReadRequest(id) {
  return {
    type: '@products/READ_REQUEST',
    payload: { id },
  };
}

export function productReadSuccess(data) {
  return {
    type: '@products/READ_SUCCESS',
    payload: { data },
  };
}

export function productReadFailure() {
  return {
    type: '@products/READ_FAILURE',
  };
}

export function productUpdateRequest(data) {
  return {
    type: '@products/UPDATE_REQUEST',
    payload: { data },
  };
}

export function productUpdateSuccess(data) {
  return {
    type: '@products/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function productUpdateFailure() {
  return {
    type: '@products/UPDATE_FAILURE',
  };
}

export function productDeleteRequest(id) {
  return {
    type: '@products/DELETE_REQUEST',
    payload: { id },
  };
}

export function productDeleteSuccess() {
  return {
    type: '@products/DELETE_SUCCESS',
  };
}

export function productDeleteFailure() {
  return {
    type: '@products/DELETE_FAILURE',
  };
}
