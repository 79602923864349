import produce from 'immer';

const INITIAL_DATA = {
  cep: '',
  logradouro: '',
  complemento: '',
  bairro: '',
  localidade: '',
  uf: '',
  ibge: '',
  gia: '',
  ddd: '',
  siafi: ''
};

const INITIAL_STATE = {
  data: INITIAL_DATA,
  loading: false,
  error: false,
  message: ''
};

export default function zipcode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@zipcode/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
        draft.message = '';
      });

    case '@zipcode/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.data;
      });

    case '@zipcode/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.data = INITIAL_DATA;
        draft.message = action.payload.error;
      });

    default:
      return state;
  }
}
