import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';

import Modal from '~/components/Modal';

import { attributeCreateRequest } from '~/store/modules/attribute/actions';

export default function Create({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.attribute.loading);
  const error = useSelector(store => store.attribute.error);

  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(attributeCreateRequest(values));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({
      name: '',
      active: 'true',
    });
  }

  useEffect(() => {
  }, []);

  useMemo(() => {
    if (isSaving && !loading) {
      setIsSaving(false);

      if (!error) {
        onReset();
        setIsVisible(false);
      }
    }
  }, [loading]);

  return (
    <Modal
      title="Novo atributo"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
