export const listYesNo = [
  { key: 'true', value: "Sim" },
  { key: 'false', value: "Não" }
];

export const avatarColors = [
  '#eb2f96',
  '#f5222d',
  '#fadb14',
  '#fa8c16',
  '#13c2c2',
  '#52c41a',
  '#1890ff',
  '#722ed1',
  '#2f54eb',
  '#6eb2f9',
  '#cfa541',
  '#d1a4fa',
  '#a0d000'
];

export const listPropertyModalities = [
  { id: 1, name: 'Venda' },
  { id: 2, name: 'Aluguel' }
]

export const listPropertyStatus = [
  { key: 1, value: "Disponível" },
  { key: 2, value: "Em negociação" },
  { key: 3, value: "Finalizado" }
];

export const listLevels = [
  { key: 3, value: "Cliente" },
  { key: 5, value: "Corretor" },
  { key: 7, value: "Administrativo" },
  { key: 9, value: "Master" }
];
