import { combineReducers } from 'redux';

import auth from './auth/reducer';
import attribute from './attribute/reducer';
import attributeValue from './attributeValue/reducer';
import category from './category/reducer';
import product from './product/reducer';
import user from './user/reducer';
import zipcode from './zipcode/reducer';

export default combineReducers({
  auth,
  attribute,
  attributeValue,
  category,
  product,
  user,
  zipcode
});
