import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import {
  categoryIndexRequest, categoryIndexSuccess, categoryIndexFailure, categoryCreateSuccess, categoryCreateFailure,
  categoryReadSuccess, categoryReadFailure, categoryUpdateSuccess, categoryUpdateFailure, categoryDeleteSuccess,
  categoryDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    let { filters } = payload;

    if (!filters) filters = [];
    filters.push({ deleted: 0 });

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `categories${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(categoryIndexSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(categoryIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('name', values.name);
    if (values.categoryId) formData.append('categoryId', values.categoryId);

    const response = yield call(api.post, 'categories', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(categoryCreateSuccess(data));
    yield put(categoryIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(categoryCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `categories/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(categoryReadSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(categoryReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const values = payload.data;

    const formData = new FormData();

    formData.append('name', values.name);
    if (values.categoryId) formData.append('categoryId', values.categoryId);
    formData.append('active', values.active === 'true' ? 1 : 0);

    const response = yield call(api.post, `categories/${values.id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(categoryUpdateSuccess(data));
    yield put(categoryIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(categoryUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `categories/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(categoryDeleteSuccess());
    yield put(categoryIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(categoryDeleteFailure());
  }
}

export default all([
  takeLatest('@categories/INDEX_REQUEST', onIndex),
  takeLatest('@categories/CREATE_REQUEST', onCreate),
  takeLatest('@categories/READ_REQUEST', onRead),
  takeLatest('@categories/UPDATE_REQUEST', onUpdate),
  takeLatest('@categories/DELETE_REQUEST', onDelete),
]);
