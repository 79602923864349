import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, DatePicker, Select, Skeleton } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { listYesNo } from '~/helpers/lists';
import { isNullOrEmpty } from '~/helpers/util';

import { categoryUpdateRequest } from '~/store/modules/category/actions';

import { Container } from './styles';

export default function Update({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const saving = useSelector(store => store.category.loading);
  const error = useSelector(store => store.category.error);
  const registry = useSelector(store => store.category.registry);
  const dataSource = useSelector(store => store.category.list);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  function onFinish(values) {
    console.log({ values });
    dispatch(categoryUpdateRequest(values));
    setIsSaving(true);
  }

  useEffect(() => {
    setLoading(true);
  }, []);

  useMemo(() => {
    setLoading(true);

    if (!isNullOrEmpty(registry?.id)) {
      form.setFieldsValue({
        id: registry.id,
        name: registry.name,
        categoryId: !registry.categoryId ? '' : registry.categoryId,
        active: Number(registry.active) === 1 ? 'true' : 'false',
      });

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);

      if (!error) setIsVisible(false);
    }
  }, [saving]);

  return (
    <Modal
      title="Editar categoria"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={600}
      processing={isSaving}
      disabled={loading}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <Row gutter={8}>
            <Col xs={24} md={20}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Nome é obrigatório' }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={4}>
              <Form.Item
                label="Ativo?"
                name="active"
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Select>
                    {listYesNo.map(d => (
                      <Select.Option key={d.key} value={d.key}>{d.value}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Superior"
                name="categoryId"
              >
                {loading ? (
                  <Skeleton.Button active size="small" block />
                ) : (
                  <Select>
                    <Select.Option key="categoryId-null" value="">Sem categoria</Select.Option>
                    {dataSource?.map(d => (
                      <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal >
  );
}
