export function categoryIndexRequest(filters) {
  return {
    type: '@categories/INDEX_REQUEST',
    payload: { filters },
  };
}

export function categoryIndexSuccess(data) {
  return {
    type: '@categories/INDEX_SUCCESS',
    payload: { data },
  };
}

export function categoryIndexFailure() {
  return {
    type: '@categories/INDEX_FAILURE',
  };
}

export function categoryCreateRequest(data) {
  return {
    type: '@categories/CREATE_REQUEST',
    payload: { data },
  };
}

export function categoryCreateSuccess(data) {
  return {
    type: '@categories/CREATE_SUCCESS',
    payload: { data },
  };
}

export function categoryCreateFailure() {
  return {
    type: '@categories/CREATE_FAILURE',
  };
}

export function categoryReadRequest(id) {
  return {
    type: '@categories/READ_REQUEST',
    payload: { id },
  };
}

export function categoryReadSuccess(data) {
  return {
    type: '@categories/READ_SUCCESS',
    payload: { data },
  };
}

export function categoryReadFailure() {
  return {
    type: '@categories/READ_FAILURE',
  };
}

export function categoryUpdateRequest(data) {
  return {
    type: '@categories/UPDATE_REQUEST',
    payload: { data },
  };
}

export function categoryUpdateSuccess(data) {
  return {
    type: '@categories/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function categoryUpdateFailure() {
  return {
    type: '@categories/UPDATE_FAILURE',
  };
}

export function categoryDeleteRequest(id) {
  return {
    type: '@categories/DELETE_REQUEST',
    payload: { id },
  };
}

export function categoryDeleteSuccess() {
  return {
    type: '@categories/DELETE_SUCCESS',
  };
}

export function categoryDeleteFailure() {
  return {
    type: '@categories/DELETE_FAILURE',
  };
}
