import styled from 'styled-components';
import { Col, Space, Dropdown as _Dropdown, Menu as _Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  min-height: 64px;
  flex-direction: row;
  align-items: center;
  background-color: var(--header-background);
  z-index: 666;
  padding: 15px 20px;
`;

export const Content = styled(Space)`
  display: flex;
  width: calc(100% - 50px);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  h1 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  img {
    height: 70px;
  }
`;

export const Dropdown = styled(_Dropdown)`
  cursor: pointer;
`;

export const Menu = styled(_Menu)`

`;

export const MenuIcon = styled(MenuOutlined)`
  color: var(--secondary-text);
  cursor: pointer;
  user-select: none;
  font-size: 20px;
`;

export const UserMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 1px 0 0 0;
  border-top-width: 0;
  width: 280px;
  min-width: 160px;
  margin: 1px;
  margin-top: 11px;
  margin-right: -68px;
  box-shadow: none;
  border-color: var(--border-secondary);
  list-style: none;
  background-color: var(--body-background);

  ul {
    width: 100%;
    background-color: var(--body-background);

    li {
      width: 100%;
      background-color: var(--body-background);

      svg {
        margin-right: 10px;
      }
    }
  }

  .user-menu-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: var(--body-background);

    &:first-child {
      height: 175px;
      text-align: center;
      background-color: var(--header-background);

      p {
        color: var(--input-text);
        font-size: 17px;
        margin: 0;
        margin-top: 10px;
      }
    }

    &:last-child {
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--body-background);
    }
  }
`;

export const UserInfo = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
`;

export const UserName = styled.span`
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
`;

export const UserPerfil = styled.span`
  color: rgba(255, 255, 255, 0.65);
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;
